import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AccountDelete = () => {
  // useEffect(() => {
  //   window.location.replace(
  //     "https://d1o76vx8zcsrkq.cloudfront.net/account-deletion"
  //   );
  // }, []);
  return (
    <div className="text-white bg-black flex flex-col justify-between h-dvh h-full space-y-8 p-4 text-left sm:px-4 lg:px-8 2xl:px-10 4xl:px-48">
      <div>
        <Link to="/">
          <img
            className="h-10 w-auto object-contain  mx-auto xl:mx-0"
            src="/images/logoBangla.png"
            alt="Workflow"
          />
        </Link>

        <p className="text-lg xl:text-3xl text-center font-bold uppercase">
          Satarko Account Deletion
        </p>
        <div className="space-y-4">
          <p>
            To delete your{" "}
            <span className="text-yellow-500 font-bold">“Satarko”</span>{" "}
            account, please follow the steps-
          </p>
          <ul className="space-y-4 ml-6 list-disc">
            <li>Go to Google playstore and download the “Satarko” app.</li>
            <li>Open the app and login to your account.</li>
            <li>
              Go to profile section, there you will find{" "}
              <b className="text-yellow-500">delete account</b> option.
            </li>
            <li>
              Click the <b className="text-yellow-500">delete account</b> button
              and system will delete all information and data for the user.
            </li>
          </ul>
        </div>
      </div>

      <footer className=" w-full mx-auto border-t border-gray-400/20 text-[10px]">
        <p className="text-center pt-4">
          Copyright 2024{" "}
          <Link
            to="https://zicoretech.com/"
            className="text-iconColor uppercase"
          >
            Zicore Technologies.
          </Link>{" "}
          Designed By{" "}
          <Link to="/" className="text-amber-500 uppercase">
            Satarko
          </Link>
        </p>
      </footer>
    </div>
  );
};

export default AccountDelete;
