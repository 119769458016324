import { createSlice } from "@reduxjs/toolkit";
import { ToastContainer, toast } from "react-toastify";

export const RegisterSlice = createSlice({
  name: "register",
  initialState: {
    registerInfo: [],
    errors: [],
  },

  reducers: {
    getRegisterInfo: (state, action) => {
      state.registerInfo = action.payload;
      // console.log(action, "reducer")
    },

    getRegisterfoSuccess: (state, action) => {
      state.registerInfo = action.payload;
      // console.log(action.payload, "reducer")

      toast.success(`${state.registerInfo.data.message}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    getErrors: (state, action) => {
      state.errors = action.payload.response.data;
      // console.log(state.errors, "reducer");

      toast.error(`${state.errors.error}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  },
});

<ToastContainer />;

export const { getRegisterInfo, getErrors, getRegisterfoSuccess } =
RegisterSlice.actions;

export default RegisterSlice.reducer;
