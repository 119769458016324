import axios from "axios";

export const getRegisterApi = (payload) => {
  // console.log(payload, "top payload");
  const baseURL = process.env.REACT_APP_BASE_URL;
  return axios
    .post(baseURL + "users/general/register/", {
      full_name: payload.name,
      phone: payload.phone,
      password: payload.password,
      otp: payload.otp,
      count: payload.count,
    })
    .then(function (response) {
      // console.log(response, " api response")
      return response;
    })
    .catch((error) => {
      // console.log(error, " api error")
      return error;
    });
};
