// import { Redirect } from "react-router-dom"
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getRegisterApi } from "../../Services/API/RegisterApi";
import { getErrors, getRegisterfoSuccess } from "../Reducers/RegisterReducer";

// Watchers
function* RegisterSaga() {
  yield takeEvery("register/getRegisterInfo", getRegisterData);
}

// Sagas
function* getRegisterData(action) {
//   console.log(action.payload, "saga payload")

  try {
    const registerInfo = yield call(getRegisterApi, action.payload);
    // console.log(loginInfo, "otp saga response")

    if (registerInfo.request.status == 200) {
      yield put(getRegisterfoSuccess(registerInfo));
    } else {
      // console.log(registerInfo, "request error");

      yield put(getErrors(registerInfo));
    }
  } catch (error) {
    yield put(getErrors(error));
  }
}

export default function* rootSaga() {
  yield all([fork(RegisterSaga)]);
}
