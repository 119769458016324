import { all } from "redux-saga/effects";
import RegisterSaga from "./RegisterSaga";
import LoginSaga from "./LoginSaga";
import ForgetPassSaga from "./ForgetPassSaga";
import NotificationSaga from "./NotificationSaga";

export default function* rootSaga() {
  yield all([
    RegisterSaga(),
    LoginSaga(),
    ForgetPassSaga(),
    NotificationSaga(),
  ]);
}
