import { createSlice } from "@reduxjs/toolkit";
import { toast, ToastContainer } from "react-toastify";

export const forgetPasswordSlice = createSlice({
  name: "forget_password",
  initialState: {
    forgetPassMessage: "",
    reset_phone: "",
    errors: [],
  },

  reducers: {
    getForgetPassMessage: (state, action) => {
      state.forgetPassMessage = action.payload;
    },
    getUserNewPhone: (state, action) => {
      state.reset_phone = action.payload.phone;
      // console.log(action.payload, "reducer")
    },
    getForgetPassMessageSuccess: (state, action) => {
      state.forgetPassMessage = action.payload;
      //   console.log(action.payload);

      toast.success(`${action.payload.data.message}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    getErrors: (state, action) => {
      state.errors = action.payload;
      //   console.log(action.payload, "error from reducer")
      toast.error(`${action.payload.error}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  },
});
<ToastContainer />;

// Action creators are generated for each case reducer function
export const {
  getForgetPassMessage,
  getForgetPassMessageSuccess,
  getErrors,
  getUserNewPhone,
} = forgetPasswordSlice.actions;

export default forgetPasswordSlice.reducer;
