import axios from "axios";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

let authTokens = Cookies.get("access_token_user")
  ? Cookies.get("access_token_user")
  : null;

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${authTokens}`,
    "Content-Type": "application/json",
    accept: "application/json",
    // "Access-Control-Allow-Origin": "https://beta.satarko.com/",
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  if (!authTokens) {
    authTokens = Cookies.get("access_token_user")
      ? Cookies.get("access_token_user")
      : null;
    req.headers.Authorization = `Bearer ${authTokens}`;
  }

  const user = jwt_decode(authTokens);
  const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

  if (!isExpired) return req;
  // const navigate = useNavigate();
  // try {
  const response = await axios.post(`${baseURL}token/refresh/`, {
    refresh: Cookies.get("refresh_token_user"),
  });
  Cookies.set("refresh_token_user", response.data.refresh);
  Cookies.set("access_token_user", response.data.access);
  req.headers.Authorization = `Bearer ${response.data.access}`;
  return req;
  // } catch (error) {
  //   // console.log(error);
  //   navigate("/");
  // }
});

export default axiosInstance;
