import { createSlice } from "@reduxjs/toolkit";
import { toast, ToastContainer } from "react-toastify";
import { setCookie } from "../../Frontend/Helpers/Session";
import Cookies from "js-cookie";

// to push device id into cookies
function pushDeviceIds(array, value) {
  let new_array = JSON.parse(array);

  if (!new_array.includes(value)) {
    new_array.push(value);
    setCookie("device_ids", JSON.stringify(new_array));
  }
}
// to push hub id into cookies
function pushHubIds(array, value) {
  let new_array = JSON.parse(array);

  if (!new_array.includes(value)) {
    new_array.push(value);
    setCookie("hub_ids", JSON.stringify(new_array));
  }
}

function updateValue(arr, newValue) {
  // update value
  if (!arr.includes(newValue)) {
    arr.push(newValue);
  }
  return arr;
}

export function reset_alarm_cookie(alarm_obj) {
  var updated_alarm_cookie = JSON.stringify(alarm_obj);

  setCookie("alarm_dict", updated_alarm_cookie);
}
export function reset_alarm_room_cookie(alarm_obj) {
  var updated_alarm_cookie = JSON.stringify(alarm_obj);

  setCookie("alarm_room", updated_alarm_cookie);
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: "",
    errors: [],
    device_id_status: false,
  },

  reducers: {
    getnotifications: (state, action) => {
      state.notifications = action.payload;
      // console.log(action.payload.data);
      if (action.payload.data.notification_type !== "WIFI_SEARCH") {
        toast.info(`${action.payload.data.content}`, {
          position: "bottom-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      if (
        action.payload.data.notification_type === "DEVICE_TRIGGER" ||
        action.payload.data.notification_type === "CAM_TRIGGER"
      ) {
        // check for trigger
        let alarm_dict_cookie = Cookies.get("alarm_dict");
        // console.log("cookie", alarm_dict_cookie);

        let hub_id = action.payload.data.hub_serial_id;
        let device_id = action.payload.data.device_id;

        if (alarm_dict_cookie) {
          let alarm_dict = JSON.parse(alarm_dict_cookie);

          // check if key exists or not if exist update the value of the key with new value & reset the cookie
          if (hub_id in alarm_dict) {
            let device_arr = alarm_dict[hub_id];
            //  console.log('device_arr', device_arr)
            let updated_device_arr = updateValue(device_arr, device_id);
            //  console.log('updated device arr', updated_device_arr)
            // reset the key value
            alarm_dict[hub_id] = updated_device_arr;
            reset_alarm_cookie(alarm_dict);
          } else {
            // console.log('key not in alarm dict, so set a new key with new value')
            // generate a new key and also add the values to the key
            alarm_dict[`${hub_id}`] = [device_id];
            reset_alarm_cookie(alarm_dict);
          }
        } else {
          const temp_alarm_dict = {};
          // generate a new key and also add the values to the key
          temp_alarm_dict[`${hub_id}`] = [device_id];
          reset_alarm_cookie(temp_alarm_dict);
        }

        // for room
        let alarm_room_cookie = Cookies.get("alarm_room");
        let room_id = action.payload.data.room_id;
        if (alarm_room_cookie) {
          let alarm_room = JSON.parse(alarm_room_cookie);

          // check if key exists or not if exist update the value of the key with new value & reset the cookie
          if (room_id in alarm_room) {
            let device_arr = alarm_room[room_id];
            //  console.log('device_arr', device_arr)
            let updated_device_arr = updateValue(device_arr, device_id);
            //  console.log('updated device arr', updated_device_arr)
            // reset the key value
            alarm_room[room_id] = updated_device_arr;
            reset_alarm_room_cookie(alarm_room);
          } else {
            // console.log('key not in alarm dict, so set a new key with new value')
            // generate a new key and also add the values to the key
            alarm_room[`${room_id}`] = [device_id];
            reset_alarm_room_cookie(alarm_room);
          }
        } else {
          const temp_alarm_room = {};
          // generate a new key and also add the values to the key
          temp_alarm_room[`${room_id}`] = [device_id];
          reset_alarm_room_cookie(temp_alarm_room);
        }
      }
    },

    getErrors: (state, action) => {
      state.errors = action.payload;
      //   console.log(action.payload, "error from reducer")
      toast.error(`${action.payload.error}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  },
});
<ToastContainer />;

// Action creators are generated for each case reducer function
export const { getnotifications, getErrors } = notificationSlice.actions;

export default notificationSlice.reducer;
