import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  FaNetworkWired,
  FaBlenderPhone,
  FaCameraRetro,
  FaAngleRight,
} from "react-icons/fa";
import { LuLayoutDashboard } from "react-icons/lu";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import axiosInstance from "./Services/Interceptor";
import ReactAudioPlayer from "react-audio-player";
import audio from "./alerts/alarm-no3-14864.mp3";
import { useSelector } from "react-redux";
import { IoNotificationsSharp } from "react-icons/io5";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CgExtension } from "react-icons/cg";
import DeleteAcc from "./Frontend/Components/DeleteAcc/DeleteAcc";
import { MdOutlineContactEmergency } from "react-icons/md";
import EmergencyContact from "./Frontend/Components/EmergencyContact/EmergencyContact";
import { CiSettings } from "react-icons/ci";
import { RiLogoutBoxRLine } from "react-icons/ri";
import PrivacyPolicy from "./Frontend/Components/PrivacyPolicy/PrivacyPolicy";
import AccountDelete from "./Frontend/Components/AccountDelete/AccountDelete";

//  ==========component calling using lazy loading============
const About = React.lazy(() => import("./Frontend/Components/About/About"));
const Home = React.lazy(() => import("./Frontend/Components/Home/Home"));
const Mqtt = React.lazy(() => import("./Frontend/Components/Mqtt/Mqtt"));
const Auth = React.lazy(() => import("./Frontend/Components/Auth/Auth"));
const SingleDevice = React.lazy(() =>
  import("./Frontend/Components/Device/SingleDevice")
);
const UserProfile = React.lazy(() =>
  import("./Frontend/Components/UserProfile/UserProfile")
);
const Error = React.lazy(() => import("./Frontend/Shared/Error/Error"));

const ActivityLog = React.lazy(() =>
  import("./Frontend/Components/ActivityLog/ActivityLog")
);
const Test = React.lazy(() => import("./Frontend/Helpers/Test"));
const RoomList = React.lazy(() =>
  import("./Frontend/Components/Room/RoomList")
);
const Groups = React.lazy(() =>
  import("./Frontend/Components/Group/GroupsList")
);
const Users = React.lazy(() => import("./Frontend/Components/Users/Users"));
const SingleGroup = React.lazy(() =>
  import("./Frontend/Components/Group/SingleGroup")
);
const GroupUserList = React.lazy(() =>
  import("./Frontend/Components/Group/GroupUserList")
);
const Notification = React.lazy(() =>
  import("./Frontend/Components/WebMessage/Notification")
);
const CameraGalleryList = React.lazy(() =>
  import("./Frontend/Components/CameraGallery/CameraGalleryList")
);
const SingleCamera = React.lazy(() =>
  import("./Frontend/Components/CameraGallery/SingleCamera")
);
const SingleRoom = React.lazy(() =>
  import("./Frontend/Components/Room/SingleRoom")
);
const HubRequtestAll = React.lazy(() =>
  import("./Frontend/Components/Permission/HubRequtestAll")
);
const UserPermission = React.lazy(() =>
  import("./Frontend/Components/Permission/UserPermission")
);
const CameraDetails = React.lazy(() =>
  import("./Frontend/Components/CameraGallery/CameraDetails")
);
const HubList = React.lazy(() => import("./Frontend/Components/Hub/HubList"));
const AddDevice = React.lazy(() =>
  import("./Frontend/Components/Device/AddDevice")
);
const DeviceList = React.lazy(() =>
  import("./Frontend/Components/Device/DeviceList")
);
const TermsAndConditions = React.lazy(() =>
  import("./Frontend/Components/TermsAndConditions/TermsAndConditions")
);

export const AllRoutes = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  let authTokens = Cookies.get("access_token_user")
    ? Cookies.get("access_token_user")
    : null;
  let refreshTokens = Cookies.get("refresh_token_user")
    ? Cookies.get("refresh_token_user")
    : null;

  let notification_id = Cookies.get("notification_id")
    ? Cookies.get("notification_id")
    : null;

  // console.log(notification_id);

  const [active, setActive] = useState("");
  // console.log(active);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationBtn, setNotificationBtn] = useState(false);
  const [count, setCount] = useState(0);

  const handleLogOut = () => {
    // console.log("first")
    axios
      .post(
        baseURL + "users/logout/",
        {
          refresh_token: refreshTokens,
        },

        {
          headers: {
            Authorization: `Bearer ${authTokens}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        // return res;
        // console.log(res.data.message);
        toast.error(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .then((response) => {
        axiosInstance
          .delete(
            baseURL +
              `notification/gcm/device/delete/${parseInt(notification_id)}/`
          )
          .then((res) => {
            // return res;
            // console.log(res);
          });
      })
      .catch((err) => {
        // return err;
      });
    Cookies.remove("access_token_user");
    Cookies.remove("refresh_token_user");
    Cookies.remove("notification_id");
    Cookies.remove("notification_token");
    setTimeout(() => {
      return window.location.replace("/");
      // return window.history.pushState({}, undefined, "/");
    }, 1200);
  };

  const [AlarmType, setAlarmType] = useState(false);
  const [notificanView, setNotificationView] = useState(false);

  useEffect(() => {
    const myObject = Cookies.get("alarm_dict")
      ? JSON.parse(Cookies.get("alarm_dict"))
      : {};
    const isObjectEmpty = Object.keys(myObject).length === 0;
    if (isObjectEmpty) {
      setAlarmType(false);
    } else {
      setAlarmType(true);
    }
  }, [Cookies.get("alarm_dict")]);

  const [notificationNew, setNotificationnew] = useState(false);
  const [notificationContentAll, setNotificationContentAll] = useState();

  // console.log(notificationContentAll);

  const { notifications } = useSelector((state) => state.notification);

  const [notificationRedDot, setNotificationRedDot] = useState(false);

  useEffect(() => {
    setCount(count + 1);
    setNotificationRedDot(true);
    setNotificationnew(true);
  }, [notifications]);
  const [user, setUser] = useState();
  useEffect(() => {
    if (authTokens) {
      axiosInstance
        .get(baseURL + "notification/")
        .then((res) => {
          // console.log(res.data);
          setNotificationContentAll(res.data);
        })
        .catch((err) => {
          // console.log(err);
        });

      axiosInstance
        .get(baseURL + "profile/users/general/")
        .then(function (response) {
          // console.log(response, " api response");
          setUser(response.data);
        })
        .catch((error) => {
          // console.log(error, "  error");
        });
    }
  }, [count]);
  const [processTabView, setProcessTabView] = useState("");
  const [profileView, setProfileView] = useState(false);
  const handleDataChange = (newData) => {
    // console.log(newData, "from call back component");
    setProcessTabView(newData);
  };

  // console.log(processTabView);


  const [pagination, setPagination] = useState(null);
  useEffect(() => {
    if (pagination) {
      // console.log("first");
      axiosInstance
        .get(pagination?.replace("http://", "https://"))
        .then(function (response) {
          // console.log(response, " api response");
          setNotificationContentAll(response.data);
          const myDiv = document.getElementById("notifi");
          myDiv.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
        .catch((error) => {
          console.log(error, "  error");
        });
    }
  }, [pagination]);

  const notificationIcon = {
    HUB_ONBOARD: "/images/icons/satarko_icons/png/hub_onboard.png",
    DEVICE_ONBOARD: "/images/icons/satarko_icons/png/hub_onboard.png",
    HUB_DELETE: "/images/icons/satarko_icons/svg/red_bin_icon.svg",
    DEVICE_DELETE: "/images/icons/satarko_icons/svg/red_bin_icon.svg",
    ALL_DEVICE_DELETE: "/images/icons/satarko_icons/svg/red_bin_icon.svg",
    HUB_ONLINE: "/images/icons/satarko_icons/svg/network_icon.svg",
    HUB_OFFLINE: "/images/icons/satarko_icons/svg/network_icon_red.svg",
    DEVICE_OPEN: "/images/icons/satarko_icons/png/device_open.png",
    DEVICE_CLOSED: "/images/icons/satarko_icons/png/device_close.png",
    DEVICE_ZONE_UPDATE: "/images/icons/satarko_icons/svg/devices.svg",
    DEVICE_TRIGGER: "/images/icons/satarko_icons/png/device_trigger.png",
    HUB_ARM: "/images/icons/satarko_icons/svg/locked_icon.svg",
    HUB_DISARM: "/images/icons/satarko_icons/svg/unlocked_icon.svg",
    HUB_WIFI_PROVISION: "/images/icons/satarko_icons/svg/wifi_excellent.svg",
    HUB_SIREN_START: "/images/icons/satarko_icons/png/hub_siren_start.png",
    HUB_SIREN_END: "/images/icons/satarko_icons/png/hub_siren_end.png",
    HUB_ARM_BY_REMOTE: "/images/icons/satarko_icons/svg/locked_icon.svg",
    HUB_DISARM_BY_REMOTE: "/images/icons/satarko_icons/svg/unlocked_icon.svg",
    HUB_SIREN_END_BY_REMOTE:
      "/images/icons/satarko_icons/png/hub_siren_end.png",
    DEVICE_CONFIGURATION: "/images/icons/satarko_icons/svg/settings.svg",
    POA_STATUS_ON: "/images/icons/satarko_icons/png/output-data.png",
    POA_STATUS_OFF: "/images/icons/satarko_icons/png/output-data-red.png",
    POB_STATUS_ON: "/images/icons/satarko_icons/png/output-data.png",
    POB_STATUS_OFF: "/images/icons/satarko_icons/png/output-data-red.png",
    CAPTURE_ON_DEMAND: "/images/icons/satarko_icons/svg/add_camera_icon.svg",
    CAPTURE_ON_ALARM: "/images/icons/satarko_icons/svg/add_camera_icon.svg",
    POB_EXTERNAL_SIREN_ON: "/images/icons/satarko_icons/png/siren.png",
    POB_EXTERNAL_SIREN_OFF: "/images/icons/satarko_icons/png/siren.png",
    SENSOR_RESET_ON: "/images/icons/satarko_icons/png/reset.png",
    SENSOR_RESET_OFF: "/images/icons/satarko_icons/png/reset.png",
    PANIC: "/images/icons/satarko_icons/svg/help.svg",
    TEMPER_ON: "/images/icons/satarko_icons/svg/temper.svg",
    TEMPER_OFF: "/images/icons/satarko_icons/png/svg/temper.svg",
    LID_OPEN: "/images/icons/satarko_icons/svg/lid_open.svg",
    LID_CLOSE: "/images/icons/satarko_icons/svg/lid_close.svg",
  };

  const Notification_base_url = (data) => {
    // console.log(data.notification_type);
    let set_href = "";
    if (
      data?.notification_type === "CAPTURE_ON_DEMAND" ||
      data?.notification_type === "CAPTURE_ON_ALARM"
    ) {
      set_href = `/camera/${data?.data.event_id}/images`;
    } else {
      set_href = `/devices/${data?.data.hub_serial_id}`;
    }

    return set_href;
  };

  // theme changing functionality
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || getSystemTheme()
  );
  //for getting initially user theme preference
  // function getSystemTheme() {
  //   return window.matchMedia("(prefers-color-scheme: dark)").matches
  //     ? "dark"
  //     : "light";
  // }

  //testing, needs to delete
  function getSystemTheme() {
    return window.matchMedia("(prefers-color-scheme: light)").matches
      ? "dark"
      : "dark";
  }

  useEffect(() => {
    document.documentElement.classList.toggle("dark", theme === "dark");
    localStorage.setItem("theme", theme);
  }, [theme]);

  const handleThemeSwitch = () => {
    setTheme((prevThem) => (prevThem === "dark" ? "light" : "dark"));
  };

  return (
    <BrowserRouter>
      <section className="relative">
        <div className="relative z-10 h-screen w-full">
          {authTokens && authTokens ? (
            <div className="relative h-full">
              <div className="h-full  relative z-10">
                <div className=" h-full flex  flex-col relative z-0">
                  <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                      as="div"
                      className="fixed inset-0 flex z-40 lg:hidden"
                      onClose={setSidebarOpen}
                    >
                      <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Dialog.Overlay className="fixed inset-0 bg-secondary bg-opacity-75" />
                      </Transition.Child>
                      <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                      >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full bg-secondary focus:outline-none">
                          <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                              <button
                                type="button"
                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                onClick={() => setSidebarOpen(false)}
                              >
                                <span className="sr-only">Close sidebar</span>
                                <XIcon
                                  className="h-6 w-6 text-white"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </Transition.Child>
                          <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                            <div className="flex justify-between items-center border-b border-slate-700 pb-2">
                              <Link
                                to="/"
                                className="flex justify-left items-center flex-shrink-0 px-4"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("dashboard"),
                                  setSidebarOpen(false)
                                )}
                              >
                                <img
                                  className="h-10 w-auto object-contain"
                                  src="/images/logoBangla.png"
                                  alt="Workflow"
                                />
                              </Link>
                            </div>

                            <nav
                              className="mt-5 flex-1 space-y-2 flex flex-col justify-start"
                              aria-label="Sidebar"
                            >
                              <Link
                                to="/"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("dashboard"),
                                  setSidebarOpen(false)
                                )}
                                className={
                                  "flex gap-x-2 justify-start items-center pl-8 text-sm  font-bold  " +
                                  (processTabView === "/"
                                    ? "text-black dark:text-white"
                                    : "text-gray-600")
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                                  />
                                </svg>
                                Dashboard
                              </Link>

                              <Link
                                to="/hubs"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("hublist"),
                                  setSidebarOpen(false)
                                )}
                                className={
                                  "flex gap-x-2 justify-start items-center pl-8 text-sm font-bold  " +
                                  (processTabView === "/hubs"
                                    ? "text-gray-400"
                                    : "text-gray-600")
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
                                  />
                                </svg>
                                Hub List
                              </Link>

                              <Link
                                to="/cameras"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("cameras"),
                                  setSidebarOpen(false)
                                )}
                                className={
                                  "flex gap-x-2 justify-start items-center pl-8 text-sm font-bold  " +
                                  (processTabView === "/cameras"
                                    ? "text-gray-400"
                                    : "text-gray-600")
                                }
                              >
                                <FaCameraRetro className="h-4 w-4" />
                                Media
                              </Link>

                              <p className="text-left pl-8 pt-4 font-medium text-xs text-gray-500 flex items-center gap-x-2  ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 h-4"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                                  />
                                </svg>
                                Users Activities
                              </p>

                              <Link
                                to="/permission"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("hub_request"),
                                  setSidebarOpen(false)
                                )}
                                className={
                                  "flex gap-x-2 justify-start items-center pl-8 text-sm font-bold  " +
                                  (processTabView === "/permission"
                                    ? "text-gray-400"
                                    : "text-gray-600")
                                }
                              >
                                <CgExtension className="h-4 w-4" />
                                Hub Request
                              </Link>
                              <Link
                                to="/emergency-contact"
                                onClick={() => (
                                  setNotificationBtn(false),
                                  setActive("hub_request"),
                                  setSidebarOpen(false)
                                )}
                                className={
                                  "flex gap-x-2 justify-start items-center pl-8 text-sm font-bold  " +
                                  (processTabView === "/permission"
                                    ? "text-gray-400"
                                    : "text-gray-600")
                                }
                              >
                                <MdOutlineContactEmergency className="h-4 w-4" />
                                Emergency Contact
                              </Link>
                            </nav>
                          </div>
                          <div className="flex-shrink-0 flex flex-col pb-2">
                            <div
                              onClick={() => (
                                handleLogOut(),
                                setSidebarOpen(false),
                                setSidebarOpen(false)
                              )}
                              className="flex gap-x-2 justify-start items-center pl-8 text-sm font-bold text-gray-50 bg-red-600 py-2 "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5.636 5.636a9 9 0 1012.728 0M12 3v9"
                                />
                              </svg>
                              Logout
                            </div>
                          </div>
                          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                            <Link
                              to="/userProfile"
                              onClick={() => (
                                setNotificationBtn(false),
                                // setActive("hub_request"),
                                setSidebarOpen(false)
                              )}
                              className="flex-shrink-0 group block"
                            >
                              <div className="flex items-center">
                                <div>
                                  {user && user.data.image ? (
                                    <img
                                      className="inline-block h-10 w-10 rounded-full object-contain"
                                      src={user.data.image}
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      className="inline-block h-10 w-10 rounded-full object-contain"
                                      src="/images/userIcon.png"
                                      alt=""
                                    />
                                  )}
                                </div>
                                <div className="ml-3">
                                  <div className="text-base font-medium text-gray-300 group-hover:text-gray-900">
                                    {user && user.data.full_name ? (
                                      <p>{user.data.full_name}</p>
                                    ) : (
                                      <p>Profile</p>
                                    )}
                                  </div>
                                  <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                                    View profile
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </Transition.Child>
                      <div className="flex-shrink-0 w-14" aria-hidden="true">
                        {/* Force sidebar to shrink to fit close icon */}
                      </div>
                    </Dialog>
                  </Transition.Root>

                  {/* Static topBar for desktop */}
                  <nav className="hidden lg:flex lg:flex-shrink-0 justify-between text-white items-center bg-lightPrimary font-poppins dark:bg-primary py-3 px-6">
                    <div className="flex justify-center items-center gap-x-8 h-auto">
                      {/* logo  */}
                      <Link to="/" className="mr-6">
                        <img
                          className="h-14 w-auto object-contain filter hue-rotate-30 dark:hue-rotate-0"
                          src="/images/logoBangla.png"
                          alt="logo"
                        />
                      </Link>
                      <Link
                        to="/"
                        className="flex flex-col gap-y-1 justify-center items-center "
                      >
                        <LuLayoutDashboard className="h-5 w-5 text-lightAccent dark:text-white" />
                        <p
                          className={
                            (processTabView === "/"
                              ? "dark:text-white text-lightAccent "
                              : "dark:text-gray-500  text-lightSecondary  ") +
                            " uppercase dark:font-bold font-semibold text-xs xl:text-sm"
                          }
                        >
                          Dashboard
                        </p>
                      </Link>
                      <Link
                        to="/hubs"
                        className="flex flex-col gap-y-1 justify-center items-center "
                      >
                        <FaNetworkWired className="h-5 w-5  text-lightAccent dark:text-white" />

                        <p
                          className={
                            (processTabView === "/hubs"
                              ? "dark:text-white text-lightAccent "
                              : "dark:text-gray-500  text-lightSecondary ") +
                            " uppercase font-bold text-xs xl:text-sm"
                          }
                        >
                          Hub List
                        </p>
                      </Link>

                      <Link
                        to="/cameras"
                        className="flex flex-col gap-y-1 justify-center items-center "
                      >
                        <FaCameraRetro className="h-5 w-5  text-lightAccent dark:text-white" />
                        <p
                          className={
                            (processTabView === "/cameras"
                              ? "dark:text-white text-lightAccent "
                              : "dark:text-gray-500  text-lightSecondary ") +
                            " uppercase font-bold text-xs xl:text-sm"
                          }
                        >
                          Media
                        </p>
                      </Link>
                    </div>

                    <div className="flex justify-center items-center gap-x-4 h-auto">
                      {notifications.data &&
                      notifications.data.notification_type ===
                        "DEVICE_TRIGGER" &&
                      AlarmType ? (
                        <Link to="/hubs">
                          <ReactAudioPlayer
                            className="hidden "
                            src={audio}
                            listenInterval={1000}
                            autoPlay
                            loop
                            volume={audio || 0.7}
                          />

                          <div className="flex justify-center items-center gap-x-2 border border-red-600 py-1 px-3 rounded-md bg-white dark:bg-black">
                            <img
                              className="h-8 w-full object-contain"
                              src="/images/error/warning.gif"
                              alt="alarm"
                            />
                            <img
                              className="h-8 w-full object-contain"
                              src="/images/error/warning1.gif"
                              alt="alarm"
                            />
                          </div>
                        </Link>
                      ) : (
                        ""
                      )}
                      {/* dark button  */}
                      {/* <Menu className="group">
                        <button
                          onClick={handleThemeSwitch}
                          className="rounded-xl outline-none focus:outline-none relative"
                        >
                          {theme === "light" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6 outline-none text-black"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2.5"
                              stroke="white"
                              className="w-6 h-6 outline-none"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                              />
                            </svg>
                          )}
                          <div className="absolute bg-black bottom-2 right-0 w-20  text-white text-sm rounded hidden group-hover:block">
                            {theme === "light" ? "Go Dark " : "Go Light"}
                          </div>
                        </button>

                      </Menu> */}

                      {/* Events  */}
                      <div
                        onClick={() => (
                          setNotificationView(true),
                          setNotificationRedDot(false)
                        )}
                        className="relative flex justify-center items-center gap-x-2 bg-lightAccent dark:bg-blue-700 text-white px-4 rounded-md py-1 3xl:py-1.5 shadow cursor-pointer"
                      >
                        <span className="font-medium ">Events</span>
                        <IoNotificationsSharp className=" relative h-5 w-5 text-white " />
                        <div
                          className={
                            "absolute top-1 right-3 h-2 w-2 border border-white animate-bounce bg-red-600 rounded-full " +
                            (notifications &&
                            notificationRedDot &&
                            notificationContentAll?.count
                              ? "block"
                              : "hidden")
                          }
                        ></div>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => setProfileView(true)}
                      >
                        {user && user.data.image ? (
                          <img
                            className="inline-block h-12 w-12 rounded-full object-contain border-[2px] border-lightAccent dark:border-gray-600 p-0.5"
                            src={user.data.image}
                            alt="profile"
                          />
                        ) : (
                          <img
                            className="inline-block h-10 w-10 rounded-full object-contain border-[2px] border-lightAccent dark:border-gray-600 p-0.5"
                            src="/images/userIcon.png"
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  </nav>
                  <div className=" flex flex-col min-w-0 flex-1 overflow-hidden ">
                    <div className="lg:hidden">
                      <div className="flex items-center justify-between bg-lightPrimary dark:bg-secondary border-b border-gray-500 px-4 py-2">
                        <Link
                          to="/"
                          className="flex justify-center items-center flex-shrink-0 px-4"
                          onClick={() => (
                            setNotificationBtn(false), setActive("dashboard")
                          )}
                        >
                          <img
                            className="h-10 w-auto object-contain"
                            src="/images/logoBangla.png"
                            alt="Workflow"
                          />
                        </Link>
                        <div>
                          <button
                            type="button"
                            className="-mr-3 lg:h-10 xl:h-12  w-12 inline-flex items-center justify-center rounded-md text-gray-300 hover:text-gray-300"
                            onClick={() => setSidebarOpen(true)}
                          >
                            <span className="sr-only">Open sidebar</span>
                            <MenuIcon
                              className="h-6 w-6 text-lightSecondary dark:text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* routes */}
                    <div className="flex-1 relative z-0 flex ">
                      <main className="flex-1 relative z-0 sm:overflow-y-auto focus:outline-none">
                        {/* Start main area*/}
                        <div className="absolute inset-0 z-0 h-[95%] sm:h-full">
                          <Routes>
                            <Route
                              exact
                              path="/"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Home onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/websocket"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Notification />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/mqtt"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Mqtt />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/test"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Test />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/about"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <About />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/userProfile"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <UserProfile
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/devices/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <DeviceList onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />

                            <Route
                              exact
                              path="/:id/add-device"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full ">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <AddDevice onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />

                            <Route
                              exact
                              path="/hubs"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <HubList onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/device/:hub/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <SingleDevice
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />

                            <Route
                              exact
                              path="/activity"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <ActivityLog
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />

                            <Route
                              exact
                              path="/rooms"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <RoomList onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/room/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <SingleRoom onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/groups"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Groups onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/group/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <SingleGroup
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/users"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Users onDataChange={handleDataChange} />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/permission"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <HubRequtestAll
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/permission/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <UserPermission
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/usersList"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <GroupUserList
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/cameras"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full ">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <CameraGalleryList
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            {/* <Route
                                exact
                                path="/camera/:id/events"
                                element={
                                  <React.Suspense
                                    fallback={
                                      <div className="flex justify-center items-center h-full w-full">
                                        <img
                                          className="h-72 w-auto object-contain "
                                          src="/images/loading.gif"
                                          alt="loader"
                                        />
                                      </div>
                                    }
                                  >
                                    <CameraEvents
                                      onDataChange={handleDataChange}
                                    />
                                  </React.Suspense>
                                }
                              /> */}
                            <Route
                              exact
                              path="/cameraDetails/:id"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <CameraDetails
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/camera/:id/images"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <SingleCamera
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            {/* <Route
                              exact
                              path="/terms-and-conditions"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <TermsAndConditions
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            /> */}

                            <Route
                              exact
                              path="/terms-and-conditions"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <TermsAndConditions
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />

                            <Route />
                            <Route
                              exact
                              path="/delete-account"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <DeleteAcc />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/emergency-contact"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <EmergencyContact />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/privacy-policy"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <PrivacyPolicy
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/*"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  <Error />
                                </React.Suspense>
                              }
                            />
                            <Route
                              exact
                              path="/account-deletion"
                              element={
                                <React.Suspense
                                  fallback={
                                    <div className="flex justify-center items-center h-full w-full">
                                      <img
                                        className="h-72 w-auto object-contain "
                                        src="/images/loading.gif"
                                        alt="loader"
                                      />
                                    </div>
                                  }
                                >
                                  {/* <TermsAndConditions onDataChange={handleDataChange} /> */}
                                  <AccountDelete
                                    onDataChange={handleDataChange}
                                  />
                                </React.Suspense>
                              }
                            />
                          </Routes>
                        </div>
                        {/* End main area */}

                        <Notification onDataChange={handleDataChange} />
                      </main>
                    </div>
                  </div>
                </div>

                {/* profile view drawer */}
                <div
                  className={
                    (profileView ? "block opacity-100" : "hidden opacity-0") +
                    " absolute inset-0 z-10 flex transition-opacity duration-200   "
                  }
                >
                  <div
                    onClick={() => {
                      setProfileView(false);
                    }}
                    className="flex-1 bg-primary opacity-70"
                  ></div>
                  <div className="max-w-[30%] 2xl:max-w-[20%] w-full bg-lightPrimary dark:bg-black h-full text-white p-8 space-y-2 divide-y divide-primary flex flex-col justify-between">
                    <div className="h-full w-full divide-y divide-primary space-y-2">
                      {user && user.data.image ? (
                        <img
                          className="inline-block h-24 w-24 rounded-full object-contain border-[2px] border-lightAccent dark:border-gray-600 p-0.5"
                          src={user.data.image}
                          alt="profile"
                        />
                      ) : (
                        <img
                          className="inline-block h-24 w-24 rounded-full object-contain border-[2px] border-gray-600 p-0.5"
                          src="/images/userIcon.png"
                          alt=""
                        />
                      )}

                      {user && user.data.full_name ? (
                        <p className="text-sm text-black dark:text-gray-400  font-semibold py-2 uppercase pt-2 ">
                          {user.data.full_name}
                        </p>
                      ) : (
                        <p className="text-sm text-lightSecondary dark:text-gray-400 font-semibold py-2 uppercase pt-2 ">
                          Profile
                        </p>
                      )}

                      <div className="py-2 space-y-2 my-2 ">
                        <div className="flex justify-start items-center gap-x-1">
                          <div className="flex justify-start items-center gap-x-1">
                            <FaBlenderPhone className="h-4 w-4 text-lightSecondary dark:text-white" />
                            <p className="uppercase text-sm font-semibold text-lightSecondary dark:text-gray-500">
                              Contact :
                            </p>
                          </div>
                          <p className="font-bold text-lightSecondary dark:text-white ">
                            {user && user.data.user_id?.phone}
                          </p>
                        </div>
                      </div>
                      <div className="py-2 space-y-2">
                        <div className="flex justify-between items-center bg-lightSecondary dark:bg-primary p-4 rounded-md cursor-pointer">
                          <Link
                            to="/userProfile"
                            className="flex justify-start items-center gap-x-2"
                            onClick={() => {
                              setProfileView(false);
                            }}
                          >
                            <CiSettings className="w-5 h-5 text-lightPrimary dark:text-white" />

                            <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                              Profile Setting
                            </p>
                          </Link>

                          <FaAngleRight className="w-5 h-5 text-lightPrimary dark:text-white" />
                        </div>
                        <Link
                          to="/permission"
                          onClick={() => setProfileView(false)}
                          className="block bg-lightSecondary dark:bg-primary p-4 rounded-md cursor-pointer"
                        >
                          <div className="flex justify-between items-center bg-opacity-30">
                            <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                              Hub Request
                            </p>
                            <CgExtension className="h-6 w-6 text-lightPrimary dark:text-white" />
                          </div>
                        </Link>
                        <Link
                          to="/emergency-contact"
                          onClick={() => setProfileView(false)}
                          className="flex justify-between items-center bg-lightSecondary dark:bg-primary  p-4 rounded-md cursor-pointer"
                        >
                          <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                            Emergency Contact
                          </p>

                          <MdOutlineContactEmergency className="h-5 w-5 text-lightPrimary dark:text-white" />
                        </Link>
                      </div>
                    </div>
                    <div className="py-4">
                      <img
                        className=" h-44 w-full object-contain"
                        src="/images/playStore.png"
                        alt="playstore"
                      />
                    </div>

                    <div className="py-2">
                      <div
                        onClick={() => (handleLogOut(), setSidebarOpen(false))}
                        className="flex justify-start items-center gap-x-2 bg-lightSecondary dark:bg-primary dark:bg-opacity-30 p-4 rounded-md cursor-pointer"
                      >
                        <RiLogoutBoxRLine className="w-6 h-6 text-lightPrimary dark:text-white" />

                        <p className="uppercase font-bold text-sm text-lightPrimary dark:text-white">
                          Log out
                        </p>
                      </div>
                      <p className="text-xs py-2 text-center capitalize text-lightSecondary dark:text-white">
                        &copy; {new Date().getFullYear()} all rights reserved by{" "}
                        <Link
                          to="https://satarko.com/"
                          className="text-lightAccent dark:text-yellow-700 uppercase font-bold"
                        >
                          Shatarko
                        </Link>
                      </p>
                      <div className="flex justify-center items-center gap-8">
                        <Link
                          to="/terms-and-conditions"
                          onClick={() => setProfileView(false)}
                          className="text-[10px] dark:text-[9px] text-lightAccent dark:text-yellow-500 text-end"
                        >
                          Terms &amp; Conditions
                        </Link>
                        <Link
                          to="/privacy-policy"
                          onClick={() => setProfileView(false)}
                          className="text-[10px] dark:text-[9px] text-lightAccent dark:text-yellow-500 text-end"
                        >
                          Privacy &amp; Policy
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* notification for desktop */}
                <div
                  className={
                    (notificanView
                      ? "block opacity-100 "
                      : "hidden opacity-0") +
                    " absolute inset-0 z-10 flex transition-opacity duration-200  h-full w-full "
                  }
                >
                  <div
                    onClick={() => setNotificationView(false)}
                    className="flex-1 bg-black  justify-end items-end h-full w-full bg-opacity-70 p-3 text-white hidden sm:flex"
                  >
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.shatarko.userapp"
                      target="_blank"
                      className="bg-slate-100 w-max h-auto rounded-lg p-3"
                    >
                      <p className="text-secondary capitalize font-bold text-sm">
                        You can also download from{" "}
                      </p>
                      <img
                        className=" h-36 w-auto mx-auto object-contain "
                        src="/images/download.png"
                        alt="app add"
                      />
                    </Link>
                  </div>
                  <div className="sm:max-w-[50%] w-full bg-lightPrimary dark:bg-black h-full text-white p-6 space-y-2 divide-y divide-primary flex flex-col justify-between">
                    <div className="h-[93%] w-full divide-y divide-primary space-y-2">
                      <div className="w-full flex justify-between items-center">
                        <h1 className="py-2  capitalize text-left font-bold text-black dark:text-gray-500">
                          events
                        </h1>
                        <AiOutlineCloseCircle
                          onClick={() => setNotificationView(false)}
                          className="text-red-500 h-7 w-7 cursor-pointer"
                        />
                      </div>

                      <div
                        id="notifi"
                        className="h-[94%] overflow-y-scroll space-y-2 pr-2 w-full"
                      >
                        {notificationContentAll &&
                        notificationContentAll.count ? (
                          notificationContentAll.results.data.map(
                            (item, index) => (
                              <div key={index} className="space-y-2 relative">
                                <h3 className="py-2 text-sm font-bold sticky top-0 bg-lightSecondary w-full z-10">
                                  {item.date}
                                </h3>
                                <div
                                  id="notification"
                                  className="space-y-2 notification"
                                >
                                  {item.notification.map((item, index) => (
                                    <a
                                      // href={`/devices/${item.data.hub_serial_id}`}
                                      href={Notification_base_url(item)}
                                      onClick={() => (
                                        setNotificationBtn(false),
                                        setNotificationView(false)
                                      )}
                                      key={index}
                                      className="relative z-0 flex flex-col justify-start items-start gap-1 cursor-pointer bg-primary p-2 rounded-md"
                                    >
                                      <p className="text-xs text-right self-end">
                                        {item.time}
                                      </p>
                                      <div className="flex justify-start items-start gap-x-2 w-full">
                                        <div className="relative">
                                          <img
                                            className="relative z-0 h-6 sm:h-8 w-6 sm:w-8 border-2 border-zinc-400 rounded-full p-1"
                                            src="/images/icons/notifications.svg"
                                            alt="notification icon"
                                          />

                                          <IoNotificationsSharp className=" absolute z-10 bottom-0 right-0 h-3 sm:h-4 w-3 sm:w-4 bg-blue-600 rounded-full p-1" />
                                        </div>
                                        <div className="text-xs sm:text-sm font-semibold text-gray-400 capitalize text-start w-full flex-1 flex justify-between items-start">
                                          <p className="flex flex-col gap-0.5">
                                            <span className="text-[10px] text-iconColor font-medium">
                                              {item.data?.event_type}
                                            </span>
                                            <span>
                                              {item.content}
                                              {/* -
                                              {item.notification_type} */}
                                            </span>
                                          </p>

                                          <img
                                            className=" h-4 w-4 text-white"
                                            src={
                                              notificationIcon[
                                                item.notification_type
                                              ]
                                            }
                                            alt="notification"
                                          />
                                        </div>
                                      </div>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            )
                          )
                        ) : (
                          <div className="col-span-3 space-y-3 pt-8 w-full flex flex-col justify-center items-center">
                            <img
                              className="h-44 w-auto mx-auto object-contain"
                              src="/images/no_req_data.png"
                              alt="No data"
                            />
                            <p className="text-base font-semibold text-red-500 text-center">
                              No notification available
                            </p>
                          </div>
                        )}

                        {notificationContentAll ? (
                          <div className="flex justify-end gap-2 items-center text-white">
                            {notificationContentAll &&
                            notificationContentAll.previous ? (
                              <p
                                onClick={() =>
                                  setPagination(notificationContentAll.previous)
                                }
                                className="cursor-pointer text-bold text-sm text-lightPrimary dark:text-white bg-lightAccent dark:bg-primary px-6 py-1 rounded-md flex justify-center items-end gap-x-1"
                              >
                                Previous
                              </p>
                            ) : (
                              <p className="text-bold text-sm text-lightPrimary dark:text-white bg-lightAccent dark:bg-primary opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                                Previous
                              </p>
                            )}
                            {notificationContentAll &&
                            notificationContentAll.next ? (
                              <p
                                onClick={() =>
                                  setPagination(notificationContentAll.next)
                                }
                                className="cursor-pointer text-bold text-sm text-lightPrimary dark:text-white bg-lightAccent dark:bg-primary px-6 py-1 rounded-md flex justify-center items-end gap-x-1"
                              >
                                Next
                              </p>
                            ) : (
                              <p className="text-bold text-sm text-lightPrimary dark:text-white bg-lightAccent dark:bg-primary opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                                Next
                              </p>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="py-2">
                      <p className="text-xs py-2 text-center capitalize text-black dark:text-white">
                        &copy; 2024 all rights reserved by{" "}
                        <span className="text-lightAccent dark:text-yellow-700 uppercase font-bold">
                          Shatarko
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <img
                className="absolute inset-0 z-0 h-full w-full object-cover  "
                src="/images/wallpaper/c2.svg"
                alt="bg"
              />
            </div>
          ) : (
            <Routes>
              <Route
                exact
                path="/terms-and-conditions"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <TermsAndConditions onDataChange={handleDataChange} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/account-deletion"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <AccountDelete onDataChange={handleDataChange} />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path="/privacy-policy"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <PrivacyPolicy onDataChange={handleDataChange} />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <Auth />
                  </React.Suspense>
                }
              />
              <Route
                exact
                path="/delete-account"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <DeleteAcc />
                  </React.Suspense>
                }
              />

              <Route
                exact
                path="/*"
                element={
                  <React.Suspense
                    fallback={
                      <div className="flex justify-center items-center h-full w-full">
                        <img
                          className="h-72 w-auto object-contain "
                          src="/images/loading.gif"
                          alt="loader"
                        />
                      </div>
                    }
                  >
                    <Error />
                  </React.Suspense>
                }
              />
            </Routes>
          )}
        </div>
      </section>

      <ToastContainer />
    </BrowserRouter>
  );
};
