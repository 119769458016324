import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import { toast, ToastContainer } from "react-toastify";
import { setCookie } from "./Frontend/Helpers/Session";
import axiosInstance from "./Services/Interceptor";
import { useEffect } from "react";

const apiKey = process.env.REACT_APP_FIREBASE_APIKEY;
const messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID;
const appId = process.env.REACT_APP_FIREBASE_APPID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENTID;

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "django-push-demo.firebaseapp.com",
  projectId: "django-push-demo",
  storageBucket: "django-push-demo.appspot.com",
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId,
};

export const FireNotification = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  let currentToken = Cookies.get("notification_token");
  let accessToken = Cookies.get("access_token_user");

  // Example function to retrieve cookie
  if (!currentToken && accessToken) {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    getToken(messaging, {
      vapidKey:
        "BOSPqZgDOnFVY_GxGFoMISN_IARqrgjpbCkRSF9H9rrY4AsbiL5B-kMVcKW74QgQ0b5hA_o-v_5jjwQvDbjlKJU",
    })
      .then((currentToken) => {
        // console.log(currentToken,"beta");

        setCookie("notification_token", currentToken);

        axiosInstance
          .post(baseURL + "notification/gcm/device/create/", {
            registration_id: currentToken,
          })
          .then(function (response) {
            // return response
            setCookie("notification_id", response.data.data.id);
            // console.log(response);
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((err) => {
        // console.log("An error occurred while retrieving token. ", err);
      });
    onMessage(messaging, (payload) => {
      // console.log("Message received. ", payload);
    });
  }


  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
      />
    </>
  );
};
