import { combineReducers } from "@reduxjs/toolkit";

import RegisterReducer from "./RegisterReducer";
import LoginReducer from "./LoginReducer";
import ForgetPassReducer from "./ForgetPassReducer";
import NotificationReducer from "./NotificationReducer";

const RootReducer = combineReducers({
  registerInfo: RegisterReducer,
  loginInfo: LoginReducer,
  forgetPass: ForgetPassReducer,
  notification: NotificationReducer,
});

export default RootReducer;
