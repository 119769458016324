import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon, PhoneIcon } from "@heroicons/react/outline";
import React, { Fragment, useEffect, useState } from "react";
import { LuBellDot } from "react-icons/lu";
import { MdDeleteOutline, MdOutlineContactEmergency } from "react-icons/md";
import { GiBellShield } from "react-icons/gi";
import { useSelector } from "react-redux";
import axiosInstance from "../../../Services/Interceptor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const EmergencyContact = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [count, setCount] = useState(0);

  const { notifications } = useSelector((state) => state.notification);
  useEffect(() => {
    setCount(count + 1);
  }, [notifications]);

  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(baseURL + "emergency-contact/")
      .then((res) => {
        // console.log(res.data);
        setContacts(res.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [count]);
  // delete emergency contact
  let [isOpenEmergencyContactDelete, setIsOpenEmergencyContactDelete] =
    useState(false);
  let [emergencyContactData, setEmergencyContactData] = useState();

  const handleDeleteEmergencyContact = () => {
    // console.log(emergencyContactData);
    axiosInstance
      .delete(baseURL + `emergency-contact/${emergencyContactData.id}/`)
      .then((response) => {
        // console.log(res.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenEmergencyContactDelete(false);
      })
      .catch((error) => {
        // console.log(err);
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenEmergencyContactDelete(false);
      });
  };

  // pagination
  const [pagination, setPagination] = useState(null);
  useEffect(() => {
    if (pagination) {
      axiosInstance
        .get(pagination?.replace("http://", "https://"))
        .then(function (response) {
          // console.log(response, " api response");
          contacts(response.data);
        })
        .catch((error) => {
          // console.log(error, "  error");
        });
    }
  }, [pagination]);

  //   add emergency contact
  let [isOpenAddEmergencyContact, setIsOpenAddEmergencyContact] =
    useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data, "edit ");
    axiosInstance
      .post("emergency-contact/request/", {
        phone: data.phone,
      })
      .then(function (response) {
        // console.log(response, " api response");
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenAddEmergencyContact(false);
        reset();
      })
      .catch((error) => {
        // console.log(error, "  error");
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setCount(count + 1);
        setIsOpenAddEmergencyContact(false);
        reset();
      });
  };
  return (
    <section className=" p-3 text-white bg-lightPrimary/70 dark:bg-transparent h-full  space-y-4">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-2 pr-4">
        <div className="flex justify-end items-center gap-x-2 w-full">
          <div
            onClick={() => setIsOpenAddEmergencyContact(true)}
            className="w-max flex items-center gap-x-2 px-4 py-1 text-xs sm:text-sm 4xl:text-base rounded-lg bg-green-500 dark:bg-teal-700 cursor-pointer shadow shadow-gray-700 hover:scale-105 capitalize"
          >
            <img
              className="h-3 md:h-4 w-3 md:w-4 object-contain"
              src="/images/icons/add.svg"
              alt="icon"
            />
            Add Emergency Contact
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-between items-center gap-4 h-[93%] overflow-auto">
        {/* contacts */}
        <div className="h-full w-full bg-primary rounded-lg p-4 space-y-3">
          <div className="flex justify-start items-center gap-x-2 bg-lightPrimary dark:bg-primary cursor-pointer w-max px-2 py-1 rounded-md shadow shadow-slate-700">
            <MdOutlineContactEmergency className="h-3  md:h-4 w-3  md:w-4 text-lightSecondary dark:text-white" />
            <p className="text-xs sm:text-sm 4xl:text-base uppercase font-semibold text-lightSecondary dark:text-white">
              All Emergency Contact
            </p>
          </div>

          <div className="space-y-3 h-[85%] sm:h-[90%] overflow-auto sm:p-2">
            {contacts?.results.data[0]?.emergency_contact.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center bg-secondary p-2 rounded-lg shadow shadow-slate-700"
              >
                <div className="flex justify-start items-center gap-4">
                  <p className="sm:text-3xl font-bold p-2 h-10 sm:h-14 w-10 sm:w-14 border-2 border-slate-400 rounded-full">
                    {item.name ? item.name.charAt(0) : "E"}
                  </p>
                  <div className="flex justify-start flex-col items-start">
                    <h2 className="font-semibold text-sm sm:text-base">
                      {item.name ? item.name : `Emergency Contact ${index + 1}`}
                    </h2>
                    <p className="flex justify-start items-center gap-x-1 text-xs sm:text-base">
                      <PhoneIcon className="h-4 w-4 " />
                      <span>{item.phone}</span>
                    </p>
                  </div>
                </div>
                <p
                  onClick={() => (
                    setEmergencyContactData(item),
                    setIsOpenEmergencyContactDelete(true)
                  )}
                >
                  <MdDeleteOutline className="h-6 w-6 hover:text-red-500 cursor-pointer" />
                </p>
              </div>
            ))}
          </div>
          {/* pagination */}
          {contacts && contacts.count ? (
            <div className="flex justify-end gap-2 items-center  mb-4">
              {contacts && contacts.previous ? (
                <p
                  onClick={() => setPagination(contacts.previous)}
                  className="cursor-pointer text-bold text-xs md:text-sm bg-primary px-6 py-1 rounded-md flex justify-center items-end gap-x-1 border border-gray-500"
                >
                  Previous
                </p>
              ) : (
                <p className="text-bold text-xs md:text-sm bg-primary opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                  Previous
                </p>
              )}
              {contacts && contacts.next ? (
                <p
                  onClick={() => setPagination(contacts.next)}
                  className="cursor-pointer text-bold text-xs md:text-sm bg-primary px-6 py-1 rounded-md flex justify-center items-end gap-x-1 border border-gray-500"
                >
                  Next
                </p>
              ) : (
                <p className="text-bold text-xs md:text-sm bg-primary opacity-40 px-6 py-1 rounded-md flex justify-center items-end gap-x-1">
                  Next
                </p>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {/* events */}
        <div className="h-full w-full bg-primary rounded-lg p-4 space-y-3">
          <div className="flex justify-start items-center gap-x-2 bg-lightPrimary dark:bg-primary cursor-pointer w-max px-2 py-1 rounded-md shadow shadow-slate-700">
            <LuBellDot className="h-3  md:h-4 w-3  md:w-4 text-lightSecondary dark:text-white" />
            <p className="text-xs sm:text-sm 4xl:text-base uppercase font-semibold text-lightSecondary dark:text-white">
              All Panic Events
            </p>
          </div>
          <div className="flex justify-between items-center gap-2 bg-secondary p-2 rounded-lg shadow shadow-slate-700">
            <p className="text-sm font-medium">Panic Events</p>
            <GiBellShield className="h-3  md:h-4 w-3  md:w-4 text-lightSecondary dark:text-white" />
          </div>
        </div>
      </div>
      {/* Modals */}
      <>
        {/* add Emergency contact */}
        <Transition appear show={isOpenAddEmergencyContact} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => (setIsOpenAddEmergencyContact(false), reset())}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-lightPrimary dark:bg-transparent p-3 sm:p-6 align-middle shadow-xl transition-all">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className={
                        "space-y-4 p-4 rounded-lg bg-lightPrimary dark:bg-gray-700  h-auto max-w-3xl m-auto text-white"
                      }
                    >
                      <div>
                        <h2 className="capitalize font-bold text-lightSecondary dark:text-white text-left text-xl sm:text-2xl lg:text-3xl">
                          Add Emergency Contact
                        </h2>
                        <p className="text-left font-medium text-sm text-lightSecondary/50 dark:text-white">
                          Please insert existing Satarko phone Number
                        </p>
                      </div>
                      {/* input fileds */}
                      <div className="space-y-4">
                        {/* name */}
                        <div className="">
                          <input
                            type="text"
                            className="border-b border-slate-200 px-2 py-1 w-full placeholder-slate-200 outline-none  bg-lightSecondary dark:bg-gray-700"
                            placeholder="User Phone Number"
                            {...register("phone", { required: true })}
                          />
                          {/* errors will return when field validation fails  */}
                          {errors.phone && (
                            <span className="text-xs text-red-500">
                              This field is required*
                            </span>
                          )}
                        </div>
                      </div>
                      {/* buttons */}
                      <div className="space-y-3">
                        <input
                          className="uppercase w-full bg-lightAccent hover:bg-lightAccent/80 dark:bg-green-400 hover:bg-green-500 py-1 text-white font-bold rounded-full cursor-pointer"
                          value="create"
                          type="submit"
                        />
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        {/* delete Emergency contact */}
        <Transition appear show={isOpenEmergencyContactDelete} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setIsOpenEmergencyContactDelete(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-700 bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-gray-600  align-middle transition-all">
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationCircleIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-red-600"
                          />
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-semibold leading-6 text-gray-900"
                          >
                            Delete Emergency Contact
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to delete your emergency?
                              All of your data will be permanently removed. This
                              action cannot be undone.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        onClick={() => handleDeleteEmergencyContact()}
                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto outline-none"
                      >
                        Delete
                      </button>
                      <button
                        type="button"
                        data-autofocus
                        onClick={() => setIsOpenEmergencyContactDelete(false)}
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto outline-none"
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    </section>
  );
};

export default EmergencyContact;
