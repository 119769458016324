import axios from "axios";
 

export const getForgetPassApi = (payload) => {
  //   console.log(payload, "api payload");
  const baseURL = process.env.REACT_APP_BASE_URL;
  return axios
    .post(baseURL + "users/password-reset/", {
      phone: payload.phone,
      password: payload.password,
      otp: payload.otp,
      count: payload.count,
    })
    .then(function (response) {
      //   console.log(response, "forget api response")
      return response;
    })
    .catch((error) => {
      return error;
      // console.log(error)
    });
};
